import * as React from 'react'
import { graphql } from 'gatsby'
import { Layout } from '../components/layout'
import { BlocksRenderer } from '../components/blocksRendered'
import { SeoView } from '../components/template/seo/seo.view'


const IndexPage = ({ data }) => {
  const page = data.strapiPage

  return (
    <Layout title={page.title} isVisible={page.titleHeader}>
      <SeoView data={page.seo} lang={''} />
      <BlocksRenderer blocks={page.blocks || []} />
    </Layout>
  )
}

export const pageQuery = graphql`
query {
    strapiPage(slug: { eq: "accueil" }) {
      id
      slug
      title
      blocks {
        ...BlocksFrag
      }
      seo {
        metaTitle
        metaDescription
        metaImage {
          localFile {
            url
          }
          alternativeText
        }
        metaSocial {
          socialNetwork
          title
          description
          image {
            localFile {
              url
            }
            alternativeText
          }
        }
        metaRobots
        metaViewport
        keywords
        canonicalURL
      }
    }
  }
`

export default IndexPage
